const proto = {
    init({ node } = {}) {
        this.el = node;

        if (!this.el) {
            return this;
        }

        this.onClick = this.onClick.bind(this);

        this.el.addEventListener('click', this.onClick);
        this.target = this.el.nextElementSibling;

        return this;
    },
    onClick() {
        const expanded = this.el.getAttribute('aria-expanded') === 'true';

        this.el.setAttribute('aria-expanded', !expanded);
        this.target.hidden = expanded;

        return this;
    },
};

export default (spec) => Object.create(proto).init(spec);
