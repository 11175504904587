export const getItem = (key, raw = false) => {
    try {
        const value = localStorage.getItem(key);

        if (raw) {
            return value;
        }

        return JSON.parse(value);
    } catch (err) {
        return undefined;
    }
};

export const setItem = (key, value, raw = false) => {
    try {
        if (raw) {
            localStorage.setItem(key, value);
        } else {
            localStorage.setItem(key, JSON.stringify(value));
        }
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
    }
};

export const clear = (key) => {
    try {
        if (key) {
            localStorage.removeItem(key);

            return;
        }

        localStorage.clear();

        return;
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
    }
};
