import tabbed from './tabbed';
import expandable from './expandable';
import cookieConsent from './cookie-consent';
import findYourCity from './find-your-city';
import findSolution from './find-solution';
import { iframeResize } from 'iframe-resizer';
import { feedbackComponent } from "./feedback-component";
import axios from 'axios';
import imageGallery from './image-gallery';
import './slider';
import './menu-toggle';

[...document.querySelectorAll('[data-gallery]')].forEach((node) => {
    imageGallery({
        node,
    });
});

[...document.querySelectorAll('[data-tabbed]')].forEach((node) => {
    tabbed({
        node,
    });
});

[...document.querySelectorAll('[data-expandable]')].forEach((node) => {
    expandable({
        node,
    });
});

if (iframeResize) {
    const iframes = iframeResize({}, '.iframe-embed iframe');
}


findYourCity();
findSolution();
// Pages to exclude from the feedback component
const pathsExcludedFromFeedback = ['/en', '/', '/en/'];
const hostsExcludedFromFeedback = ['catalogue.city', 'cscc.stag.prophets.me'];
if (!hostsExcludedFromFeedback.includes(window.location.host)) {
    if (!pathsExcludedFromFeedback.includes(window.location.pathname)) {
        feedbackComponent();
    }
}


/* eslint-disable */
const loadGTM = () => {
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push(
            { 'gtm.start': new Date().getTime(), event: 'gtm.js' }
        );
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', window.GTM_CODE);
};
/* eslint-enable */

const consent = cookieConsent({
    selector: '.js-cookie-consent',
    key: 'OASCCookiesAccepted',
});

if (consent) {
    consent.once('accepted', loadGTM);

    if (consent.getPreference() === true) {
        loadGTM();
    }
}

let loadMoreBtn = document.getElementsByClassName('load-more');
if (loadMoreBtn[0] !== undefined) {
    loadMoreBtn[0].addEventListener('click',function(ev){
        loadSolutions(this)
    });
}

/**
 * 
 * @param loadMoreButton
 * @returns {boolean}
 */
function loadSolutions(loadMoreButton) {
    const langCode = document.documentElement.lang;
    const nextUrl =  loadMoreButton.getAttribute("data-url");
    let url = nextUrl === "" ?  window.location.origin + "/" + langCode + "/api/entries/solutions.json?offset=1" :  nextUrl
    
    const cardContainer  = document.getElementById("card-block-load-more");
    
    if(nextUrl === undefined){
        return false;
    }
   
    url = createParamFromUrl(url);
    
    // disable prevent bot-click
    loadMoreButton.setAttribute("disabled","disabled")
    loadMoreButton.innerHTML = "Loading..."
    
    // request
    axios({
        method: "GET",
        url:url,
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
    }).then((response)=>{
        
        // render html
        response.data.data.forEach(function (entry){
            cardContainer.insertAdjacentHTML("beforeend",entry.html)
        });
        
        // make it enable
        loadMoreButton.removeAttribute("disabled");
        loadMoreButton.innerHTML = "Load more";
        
        // add next link
        const nextNumber = Number(url.searchParams.get('offset')) + 1;
        url.searchParams.set("offset",nextNumber.toString());
        loadMoreButton.setAttribute("data-url",url)
    
        
        // hide button when max
        const total = response.data.meta.pagination.total_pages;
        if(total <= nextNumber){
            loadMoreButton.remove()
        }
        
    }).catch((e)=> console.log(e.message));
   
}

function createParamFromUrl(url){
    const urlParam = new URL(
      window.location.href
    );
    const theme = urlParam.searchParams.get('theme');
    const sector = urlParam.searchParams.get('sector');
    const technologyFunctionSlug = urlParam.searchParams.get('technologyFunctionSlug');
    const city = urlParam.searchParams.get('city');
    
    
    url = new URL(url);
    if(theme !== 'all' && theme !== null){
        url.searchParams.set("theme",theme)
    }
    if(sector !== 'all' && sector !== null){
        url.searchParams.set("sector",sector)
    }
    if(technologyFunctionSlug !== 'all' && technologyFunctionSlug !== null){
        url.searchParams.set("technologyFunction",technologyFunctionSlug)
    }
    if(city !== 'all' && city !== null){
        url.searchParams.set("city",city)
    }
    return url;    
}