const proto = {
    init() {
        this.node = document.querySelector('[data-find-your-city]');

        if (!this.node) {
            return this;
        }

        this.citySelect = this.node.querySelector('[data-find-your-city-city-select]');
        this.countrySelect = this.node.querySelector('[data-find-your-city-country-select]');
        this.data = JSON.parse(this.node.dataset.findYourCity);

        this.onCountryChange = this.onCountryChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.countrySelect.addEventListener('change', this.onCountryChange);
        this.node.addEventListener('submit', this.onSubmit);
        this.onCountryChange();

        return this;
    },
    onCountryChange() {
        this.citySelect.length = 1; // remove all options bar first

        if (this.countrySelect.selectedIndex < 1) {
            this.citySelect.disabled = true;
            return;
        }

        const selectedCountryData = this.data[this.countrySelect.value];

        if (selectedCountryData) {
            this.citySelect.disabled = false;

            selectedCountryData.forEach((value) => {
                this.citySelect.options[this.citySelect.options.length] = new Option(value.title, value.url);
            });
        }

        return;
    },
    onSubmit(e) {
        e.preventDefault();
        window.location = this.citySelect.value;

        return false;
    },
};

export default (spec) => Object.create(proto).init(spec);
