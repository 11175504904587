import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipeVideoPlugin from 'photoswipe-video-plugin';

const proto = {
    init({ node }) {
        const links = [...node.querySelectorAll('a')];

        const images = links.map((link) => {
            // Check if href is an image or a video
            const isVideo = /\.(mp4|webm|ogv)$/i.test(link.href);

            if (isVideo) {
                let vid = link.querySelector("video");
                link.dataset.pswpType = 'video';
                link.dataset.pswpHeight = vid.videoHeight;
                link.dataset.pswpWidth = vid.videoWidth;
                link.dataset.msrc = 'https://picsum.photos/seed/1/800/600'
            } else {
                const img = new Image();
                img.src = link.href;
                img.onload = () => {
                    link.dataset.pswpWidth = img.naturalWidth;
                    link.dataset.pswpHeight = img.naturalHeight;
                    link.dataset.cropped = true;
                }
                return img;
            }
        });

        const lightbox = new PhotoSwipeLightbox({
            gallery: node,
            children: 'div',
            showHideAnimationType: 'none',
            // showAnimationDuration: 0,
            // hideAnimationDuration: 0,
            pswpModule: () => import('photoswipe'),
            padding: { top: 40, bottom: 40, left: 100, right: 100 },
            bgOpacity: 0.2,

        });


        const videoPlugin = new PhotoSwipeVideoPlugin(lightbox, {
            // options
        });

        lightbox.init();

        return this;
    },
};

export default (spec) => Object.create(proto).init(spec);
