const menuToggleSelector = '.header__burger-menu'
const menuMobileSelector = '.mobile-menu'

const menuToggle = document.querySelectorAll(menuToggleSelector)
const menuMobile = document.querySelector(menuMobileSelector)

if (menuToggle.length && menuMobile) {
    menuToggle.forEach(toggle => {
        toggle.addEventListener('click', () => {
            menuMobile.classList.toggle('active')
        })
    })
}
