import Events from 'eventemitter3';

import { getItem, setItem, clear } from './storage';

const proto = {
    init({ selector = '.js-cookie-consent', key = 'cookiePolicyAccepted' } = {}) {
        Events.call(this);

        this.el = document.querySelector(selector);
        this.key = key;

        if (!this.el) {
            return false;
        }

        this.accept = this.el.querySelector('.js-cookie-consent-accept');
        this.refuse = this.el.querySelector('.js-cookie-consent-refuse');
        this.accept.addEventListener('click', () => this.setPreference(true));
        this.refuse.addEventListener('click', () => this.setPreference(false));

        if (this.getPreference() === null) {
            this.el.classList.add('is-active');
        }

        document.getElementById('dpn_button')?.addEventListener('click', (x) => {
            clear(this.key);
            x.preventDefault();
            this.el.classList.add('is-active');
        })

        return this;
    },
    setPreference(preference) {
        setItem(this.key, preference);
        this.el.classList.remove('is-active');

        this.emit(preference ? 'accepted' : 'refused');

        return this;
    },
    getPreference() {
        return getItem(this.key);
    }
};

export default (spec) => Object.create(Object.assign({}, proto, Events.prototype)).init(spec);
