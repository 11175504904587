const proto = {
    init() {
       
        
        const stepButton = document.getElementById('find-solution-button');
        if(stepButton !== null){
            stepButton.addEventListener("click", (event) => {
                const counter = parseInt(event.target.dataset.counter);
                const maxCounter = parseInt(event.target.dataset.maxCounter);

                const nextNumber = counter + 1;
                this.showStep(nextNumber);

                event.target.dataset.counter = nextNumber;

                if (maxCounter && nextNumber > maxCounter) {
                    this.redirectToSolutionPage();
                }
            });

            // Options for the observer (which mutations to observe)
            const observerConfig = { attributes: true, attributeFilter: ['data-counter'] };

            // Callback function to execute when mutations are observed
            const attributeChangeCallback = function(mutationsList, observer) {
                for(const mutation of mutationsList) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'data-counter') {
                        // Call a function or execute code when the attribute changes
                        if( (mutation.target.dataset.counter === mutation.target.dataset.maxCounter)) {
                            stepButton.innerHTML ="Search";
                        }else{
                            stepButton.innerHTML ="Continue";
                        }
                    }
                }
            };

            // Create an observer instance linked to the callback function
            const observer = new MutationObserver(attributeChangeCallback);

            // Start observing the target element for configured mutations
            observer.observe(stepButton, observerConfig);
        }
       

       
        

        const allStepsNav = document.querySelectorAll('.find-solutions__steps-item');
        allStepsNav.forEach((el) => {
            const stepButtonNav = el.getAttribute('data-step');
            el.addEventListener('click', (e) => {
                e.preventDefault();
                stepButton.dataset.counter = stepButtonNav;
                this.showStep(parseInt(stepButtonNav));
            });
        });

        const radioButtonsSector = document.querySelectorAll('.sector-tag input[type="radio"]');
        const labelsSector = document.querySelectorAll('.sector-tag label');
    
        radioButtonsSector.forEach(function(radioButton) {
            radioButton.addEventListener("change", function() {
                labelsSector.forEach(function(label) {
                    if (label.htmlFor === radioButton.id) {
                        label.classList.add("active");
                    } else {
                        label.classList.remove("active");
                    }
                });
            });
        });

        const radioButtonsTheme = document.querySelectorAll('.theme-tag input[type="radio"]');
        const labelsTheme = document.querySelectorAll('.theme-tag label');
    
        radioButtonsTheme.forEach(function(radioButton) {
            radioButton.addEventListener("change", function() {
                labelsTheme.forEach(function(label) {
                    if (label.htmlFor === radioButton.id) {
                        label.classList.add("active");
                    } else {
                        label.classList.remove("active");
                    }
                });
            });
        });

        const radioButtonsSolution = document.querySelectorAll('.solution-tag input[type="radio"]');
        const labelsSolution = document.querySelectorAll('.solution-tag label');
    
        radioButtonsSolution.forEach(function(radioButton) {
            radioButton.addEventListener("change", function() {
                labelsSolution.forEach(function(label) {
                    if (label.htmlFor === radioButton.id) {
                        label.classList.add("active");
                    } else {
                        label.classList.remove("active");
                    }
                });
            });
        });
    },

    showStep(step) {
        const allSteps = document.querySelectorAll('.js-solution-step');
        allSteps.forEach((el) => {
            el.classList.add('hidden');
        });
        const currentStep = document.querySelector(`.js-solution-step[data-step="${step}"]`);
        if (currentStep) currentStep.classList.remove('hidden');

        const allStepsNav = document.querySelectorAll('.find-solutions__steps-item');
        allStepsNav.forEach((el) => {
            el.classList.remove('active');
        });
        const currentStepNav = document.querySelector(`.find-solutions__steps-item[data-step="${step}"]`);
        if (currentStepNav) currentStepNav.classList.add('active');
    },

    redirectToSolutionPage() {
        const sector = document.querySelector('input[name="sector"]:checked').value;
        const theme = document.querySelector('input[name="theme"]:checked').value;
        const technologyFunction = document.querySelector('input[name="technologyFunction"]:checked').value;

        const url = `/en/solutions?sector=${sector}&theme=${theme}&technologyFunction=${technologyFunction}&home=1`;
        window.location.href = url;
    }

    
};

export default () => Object.create(proto).init();
